.swiper {
  .swiper-wrapper {
    .swiper-slide {
      height: auto;
      img {
        cursor: pointer;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &:hover {
    .swiper-button-prev {
      opacity: 1;
      left: 10px;
      transition: $transition;

      &.swiper-button-disabled {
        opacity: 0.35;
      }
    }

    .swiper-button-next {
      opacity: 1;
      right: 10px;

      &.swiper-button-disabled {
        opacity: 0.35;
      }
    }
  }

  .swiper-button-next {
    background-image: url('data:image/svg+xml,%3Csvg xmlns=\"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg\" width=\"1em\" height=\"1em\" preserveAspectRatio=\"xMidYMid meet\" viewBox=\"0 0 24 24\"%3E%3Cpath fill=\"currentColor\" d=\"M10.707 17.707L16.414 12l-5.707-5.707l-1.414 1.414L13.586 12l-4.293 4.293z\"%2F%3E%3C%2Fsvg%3E');
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    transform: translateY(-50%);
    margin-top: 0;
    right: -100%;
    transition: $transition;

    &::after {
      display: none;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  .swiper-button-prev {
    background-image: url("data:image/svg+xml,%3Csvg xmlns=\"http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg\" width=\"1em\" height=\"1em\" preserveAspectRatio=\"xMidYMid meet\" viewBox=\"0 0 24 24\"%3E%3Cpath fill=\"currentColor\" d=\"M13.293 6.293L7.586 12l5.707 5.707l1.414-1.414L10.414 12l4.293-4.293z\"%2F%3E%3C%2Fsvg%3E");
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    transform: translateY(-50%);
    margin-top: 0;
    opacity: 0;
    left: -100%;
    transition: $transition;

    &::after {
      display: none;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }
  }
}