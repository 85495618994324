@import "../global/config.scss";

.hero-banner {
  //background-image: url(../../images/homePage.png);
  //background-size: cover;
  //background-position: center center;
  height: 600px;
  //background-repeat: no-repeat;
  //transform: skew(0deg, 0deg);
  //margin-top: -70px;
  position: relative;

  .bg-img {
    position: absolute;
    inset: -70px 0 0 0;
    width: 100%;
    height: 100%;
    background-color: #61282a;

    img {
      width: 100%;
      height: 100%;

      @include media-breakpoint-down(md) {
        object-fit: cover;
      }
    }
  }

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 70px;
    left: 0;
    right: 0;
    background-color: $white;
    clip-path: polygon(100% 80%, 0% 100%, 100% 100%);
    z-index: 1;
  }

  .container {
    position: relative;
    height: 100%;
  }

  .hero-banner-inner {
    position: relative;
    height: 100%;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    @include vcenter;

    .banner-text {
      position: relative;
      top: -100px;

      h2 {
        color: $white;
        font-weight: 700;
        @include xl-title;
      }

      p {
        @include xl-text;
        color: $white;
        margin: 20px 0 40px 0;
      }

      .explore-btn {
        padding: 12px 25px;
        @include xl-text;
        text-transform: capitalize;
        max-width: 161px;
        display: block;
        margin: 0 auto;
        transition: $transition;
        font-weight: 600;
        @include buttonOutlineSecodary;
      }
    }
  }
}

.counter-main {
  padding: 0;
  min-height: 200px;
  position: relative;

  @include media-breakpoint-down(md) {
    min-height: 300px;
  }

  @include media-breakpoint-down(sm) {
    min-height: 345px;
  }

  .counter-inner {
    display: flex;
    align-items: center;
    min-height: 85px;
    position: relative;

    .counter-loader {
      position: absolute;
      inset: 0;
      z-index: 1;
      height: 100%;
      width: 100%;

      .react-loading-skeleton {
        line-height: 1.4;
      }
    }

    .left-image {
      img {
        max-width: 48px;
      }
    }

    .right-counter {
      margin-left: 20px;

      h3 {
        margin: 0;
        font-weight: 900;
        @include lg-title;
      }

      p {
        @include lg-text;
        margin: 0px;
        color: #555555;
      }
    }
  }
}

.how-it-works-section {
  padding: 110px 0px 140px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-image: url(https://d1kndubu51u47k.cloudfront.net/assets/left-dot.png);
    background-repeat: no-repeat;
    width: 240px;
    height: 140px;
    left: 0;
    top: 8%;

    @media (max-width: 1023px) {
      width: 140px;
      height: 80px;
      background-size: 100%;
    }
  }

  img {
    z-index: -1;
    position: relative;
  }

  .shadow-lg {
    img {
      z-index: 1;
    }
  }

  h2 {
    text-align: center;
    margin-bottom: 75px;
    font-weight: bold;
    @include xl-title;
    color: #181818;
  }

  .top-polygone {
    img {
      position: absolute;
      right: 0px;
      top: 0px;

      @media (max-width: 1150px) {
        width: 40%;
      }
    }
  }

  .bottom-polygone {
    img {
      position: absolute;
      left: 0px;
      bottom: 0px;

      @media (max-width: 1450px) {
        width: 40%;
        z-index: -9;
      }
    }
  }

  .works-gap {
    margin-bottom: 90px;

    @media (max-width: 1023px) {
      margin-bottom: 35px;
    }
  }

  .how-it-work-text-info {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      @include lg-title;
      color: #000000;
      font-weight: 700;
      margin: 2px;
      margin-bottom: 0px;
      display: flex;
      align-items: center;

      span {
        background-color: #c11e37;
        height: 46px;
        width: 46px;
        border-radius: 50%;
        @include vcenter;
        @include sm-title;
        font-weight: normal;
        color: #fff;
        text-align: center;
        margin-right: 18px;

        @media (max-width: 1023px) {
          height: 35px;
          width: 35px;
          margin-right: 10px;
        }
      }
    }

    p {
      color: #666;
      @include xl-text;
      margin: 24px 0px 0px 0px;
    }

    .register-polygon {
      right: 20%;
      bottom: 5%;
      position: absolute;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .observe-polygon {
      right: 0px;
      bottom: 0px;
      height: 130px;
      width: 130px;
      position: absolute;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .share-polygon {
      left: -50%;
      width: 100px;
      height: 100px;
      position: absolute;
      top: -90px;

      @media (max-width: 1820px) {
        left: -40%;
      }

      @media (max-width: 1700px) {
        left: -30%;
      }

      @media (max-width: 1600px) {
        left: -18%;
      }

      @media (max-width: 1500px) {
        left: -17%;
      }

      @media (max-width: 1380px) {
        left: -26%;
      }

      @media (max-width: 1280px) {
        left: -15%;
      }

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .engage-polygon {
      right: 50%;
      height: 100px;
      width: 100px;
      bottom: -35px;
      position: absolute;

      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .view-tutorial {
      margin-top: 24px;
      padding: 11px 26px;
      background-color: transparent;
      color: $secondary;
      @include lg-text;
      font-weight: 500;
      max-width: fit-content;
      border-radius: 99px;

      &:hover {
        background-color: $secondary;
        color: $white;
      }
    }
  }
}

// Map section css start
.map_section {
  position: relative;
  margin-top: 100px;
  min-height: 780px;
  padding: 100px 0;

  .map_inner {
    position: absolute;
    inset: 0;
    @include vcenter;
  }

  &::before {
    content: "";
    display: block;
    background: $black;
    width: 100%;
    height: 100%;
    position: absolute;
    top: -9%;
    clip-path: polygon(0 0, 100% 9%, 100% 10%, 0 10%);
    z-index: 10;
  }

  .container {
    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          img {
            max-height: 260px !important;
          }
        }
      }
    }

    .loader-wrap-img {
      max-height: 217px !important;
      //Extra css to fit image in homepage card section.
      // img{
      //   height: 180px !important;
      // }
    }
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    background-color: #fff;
    clip-path: polygon(100% 90%, 0% 100%, 100% 100%);
  }
}

.obervation-card_wrapper {
  position: relative;
  top: -270px;
  left: 0;
  right: 0;
  z-index: 11;

  .observation_card {
    .card-img {
      height: 180px;
      object-fit: cover;
      object-position: center;

      @media (max-width: 1024px) {
        height: 130px;
      }

      @include media-breakpoint-down(lg) {
        height: 120px;
      }

      @include media-breakpoint-down(md) {
        height: 160px;
      }

      @media (max-width: 474px) {
        height: 100px;
      }
    }
  }
}

// Map section css end

.get-started-section {
  padding: 110px 0px 150px;
  position: relative;
  overflow: hidden;

  .top-polygone {
    img {
      position: absolute;
      right: -11px;
      bottom: -29px;
      width: 14%;
      transform: rotate(90deg);

      @media(max-width: 767px) {
        display: none;
      }
    }
  }

  h3 {
    @include slg-title;
    margin-bottom: 18px;
    font-weight: 700;
    color: $black;
  }

  .get-start {
    padding: 15px 45px;
    box-shadow: $box-shadow-button;
    border-radius: 30px;
    @include buttonSecodary;
    @include xl-text;
  }

  .top-text-polygon {
    img {
      position: absolute;
      max-width: 80px;
      top: 10%;
      right: 50%;
      z-index: -1;
    }
  }

  .bottom-text-polygon {
    img {
      position: absolute;
      max-width: 80px;
      bottom: 25px;
      left: 30%;
      z-index: -1;
    }
  }
}