@charset "UTF-8";

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// Importing variables & Bootstrap functions & utilities
@import "../global/config.scss";

// Tooltip
@import 'tippy.js/dist/tippy';
@import 'tippy.js/animations/perspective';
@import "tippy.js/themes/light-border";
@import "../component/swiper.scss";

body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  position: relative;
  font-family: $FontFamily;
  background-color: $white;
}

input.form-control::-webkit-input-placeholder,
input::-webkit-input-placeholder,
textarea.form-control::-webkit-input-placeholder {
  color: #000;
  opacity: 0.32;
}

// input.form-control:-ms-input-placeholder { 
input.form-control:-ms-input-placeholder,
input:-ms-input-placeholder,
textarea.form-control:-ms-input-placeholder {
  color: #000;
  opacity: 0.32;
}

// input.form-control::placeholder {
input.form-control::placeholder,
input::placeholder,
textarea.form-control::placeholder {
  color: #000;
  opacity: 0.32;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $black;
  -webkit-box-shadow: 0 0 0px 1000px $input-bg inset;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=file],
input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}

::selection {
  color: #fff;
  background-color: #9900004a;
}

body.modal-open {
  padding-right: 0 !important;
  overflow: auto !important;
}

.pointer {
  cursor: pointer !important;
}

.btn {
  border-radius: 99px;

  &.disabled {
    @include disabled;
  }
}

.form-control,
.form-select {
  @include formField;
  @include lg-text;

  &:focus {
    border-color: transparent;
    box-shadow: none;
  }
}

.counter {
  font-size: 35px;
  font-weight: $font-weight-bolder;
}

.required {
  background: linear-gradient(128.5deg, #C01E37 0%, #F052C1 154.21%);
  border-radius: 2px;
  padding: 1.5px 2px 0.5px 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  color: $white;
  display: inline-block;
  margin-left: 6px;
  margin-bottom: 0;
}

.main-content {
  margin-top: 70px;
  min-height: calc(100vh - 150px);

  @include media-breakpoint-down(md) {
    margin-top: 64px;
  }
}

.container {
  @include media-breakpoint-up(xxl) {
    max-width: 90%;
  }

  @media screen and (min-width: 2000px) {
    max-width: 1800px;
  }

  @include media-breakpoint-down(lg) {
    max-width: 100%;
  }
}


.btn-secondary {
  @include buttonSecodary;
}

.btn-outline-secondary {
  @include buttonOutlineSecodary;
}

.gray-outline-btn {
  @include grayButton;
}

.map-search-container {
  padding: 20px;
  position: relative;
}

// Import any component file here

.setplaceholdersize {
  input::placeholder {
    font-size: 16px;
  }
}

.object-contain {
  object-fit: contain !important;
}

.ex-text {
  @include sm-text;
}

.showUserTag {
  position: absolute;
  right: 13px;
  bottom: 0;
  font-size: 12px;
  font-weight: bolder;

  @include media-breakpoint-down(sm) {
    bottom: -8px;
  }
}


ul.list-numbered {
  list-style-type: decimal;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    ul {
      list-style-type: disc;

      li {
        margin-bottom: 5px;
      }
    }
  }
}

.center_box {
  min-height: calc(100vh - 84px);
  @include vcenter;
}

.disabled {
  cursor: not-allowed;
}

.mark-center {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  @include vcenter;
  display: inline-flex;

  .btn {
    position: relative;
    top: 80px;
    padding: 8px 30px;
  }
}

.offline {
  position: fixed;
  inset: 0;
  z-index: 999;
  background: $white;
  @include vcenter;
}

.dash-inner-card-list {
  min-height: 170px;
  max-height: 170px;
  overflow: hidden;
  border-radius: 5px;

  > div {
    min-height: 170px;
    max-height: 170px;
  }

  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .badge-category {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 10;
    background-color: $primary;
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 10px;
    color: $white;
  }
}

.loader-wrap-img {
  position: relative;
  overflow: hidden;

  .loadingImage {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    .react-loading-skeleton {
      line-height: 1.5;
      border-radius: 0;
    }
  }
}

.cat-loader {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 3px;
  z-index: 2;
  @include vcenter;

  &:last-child {
    margin-right: 0 !important;
  }

  &:first-child {
    margin-left: 0 !important;
  }

  .skeleton {
    position: absolute;
    inset: -1px 0 0 -2px;
    width: 100%;
    height: 100%;

    span {
      display: block;
    }
  }
}

.body-loader,
.footer-loader {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding: 10px;
  background-color: $white;

  .skeleton {
    position: relative;
    top: 7px;
  }
}

.footer-loader {
  padding: 0 10px;

  .skeleton {
    position: relative;
    top: -6px;
  }
}

@import "../component/index.scss";
@import 'react-loading-skeleton/dist/skeleton.css';