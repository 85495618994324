@import "../global/config.scss";

.rc-card-loader {
  min-height: 300px;
}

.observation_card {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  z-index: 11;
  position: relative;
  min-height: 300px;
  border: 0;

  .delete-info {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    padding: 20px;
    backdrop-filter: blur(5px);
    @include vcenter;

    .btn {
      width: 100%;
      margin: 0 5px;
    }
  }

  .observation_country {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;

    .badge {
      padding: 4px 6px;
      background: rgba(0, 0, 0, 0.64);
      border-radius: 0px 6px 0px 8px;
    }

    img {
      width: 20px;
    }
  }

  .verify-card {
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(255 255 255 / 90%);
    border-radius: 6px 0 8px;
    padding: 5px;
    line-height: 0;
    z-index: 3;
  }

  .multiple-image_icon {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
    background: $white;
    opacity: 0.64;
    border-radius: 0px 8px 0px 8px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;

    svg {
      color: $black;
    }
  }

  .edit-icon {
    opacity: 0;
  }

  .card-img {
    border-radius: 8px 8px 0 0;
    cursor: pointer;

    &.no-cursor {
      cursor: auto;
      max-height: 260px !important;
    }
  }

  .card-link {
    &:hover {
      .observation_country, .top-right-action {
        opacity: 1;
      }
    }

    &:hover .edit-icon {
      opacity: 1;
    }


    .top-right-action {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
      background-color: transparentize($white, 0.2);
      border-radius: 0 0 0 10px;
      overflow: hidden;
      opacity: 0;
      @include transition();

      .icon {
        background-color: transparent;
        box-shadow: none;
        color: $dark;
        padding: 0 5px 4px;
        border-radius: 0;

        &:hover {
          background-color: transparentize($dark, 0.4);
          color: $white;
        }
      }
    }

  }

  .observation-card_body {
    padding: 25px 11px 11px;

    .observation_type {
      position: absolute;
      top: -16px;
      left: 12px;
      z-index: 2;
      @include vstart;

      .cat-img {
        overflow: hidden;
        box-shadow: 0 0 4px rgb(0 0 0 / 25%);
        width: 26px;
        height: 26px;
        padding: 2px;
        border-radius: 50%;
        display: inline-block;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .card_desc {
      .card-title {
        font-weight: bold;
        @include md-text;
      }

      .card-subtitle {
        font-weight: normal;
        @include md-text;

        .badge {
          @include xs-text;
          font-weight: 500;
          margin-left: 4px;
        }
      }
    }

    .card-details {
      .card-user_details {
        .name {
          @include md-text;
          font-weight: 400;
          width: calc(100% - 30px);
        }

        .profile-icon {
          width: 30px;
          height: 30px;
          overflow: hidden;

          img {
            object-fit: cover;
          }
        }

        .profile-text {
          width: 30px;
          height: 30px;
          font-style: normal;
          @include md-text;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

    }
  }

  .card-footer {
    padding: 6px 11px;
    border-radius: 0 0 6px 6px;
    border-top: 0;
    display: flex;
    justify-content: space-between;

    h6 {
      font-weight: 500;
      @include sm-text;
      color: $gray3;
    }

    .card-user_location {
      @include vend;

      .card-direction {
        width: 16px;
        height: 16px;
        border: 1px solid #B9BFCB;
        transform: rotate(var(--card-location-angle));

        .direction-dot {
          width: 3px;
          height: 3px;
          background: #B9BFCB;
          border-radius: 50%;
          margin-top: 1px;
        }
      }
    }

    .location-details {
      max-width: calc(100% - 50px);
      width: 100%;
      padding-right: 5px;
    }

    .direction-details {
      max-width: 50px;
      width: 100%;
    }
  }
}

